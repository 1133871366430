'use client';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { FaBed } from 'react-icons/fa';
import Link from 'next/link';
import formatter from '@/utils/formatter';

import { useRouter } from 'next/navigation';
import Image from 'next/image';


const CardProps = props => {
  const {push} = useRouter();

  const handlePropertyClick=(id)=>{

    window.open(`propiedades/${id}`, '_blank');
 
    } 
    let firstPicture;
    if (props.arrayPictures && props.arrayPictures.length > 0) {
      firstPicture = props.arrayPictures[0];
      if (firstPicture.endsWith('.jp')) {
        firstPicture += 'g'; // Agrega una 'g' al final si termina en '.jp'
      }
    }


  return (

    
    <Link href={`/propiedades/${props.property.id}`}  passHref
    target="_blank" 
    rel="noopener noreferrer">
    <Box cursor='pointer'
    //onClick={() => handlePropertyClick(props.property.id)}
    m={{
      base: '15px 5px',
      sm: '20px',
      customSmMd:'15px 3px',
      md:'20px'

    }}

      position={'relative'}
      overflow={'hidden'}
      borderRadius={'4px'}
      //boxShadow={'4px 4px 3px rgba(0, 0, 0, 0.3)'}
      w={{
        base: '340px',
        sm: '440px',
        customSmMd:'300px',
        md: '300px',
        lg: '310px',
        xl: '350px',
      }}
      height={'400px'}>
         <Image
  src={props.arrayPictures ? firstPicture : ''}
  alt="imagen propiedad"
  width={340} 
  height={275} 
  sizes="
    (max-width: 640px) 300px,
    (min-width: 640px) and (max-width: 768px) 440px,
    (min-width: 768px) and (max-width: 1024px) 300px,
    (min-width: 1024px) and (max-width: 1280px) 310px,
    (min-width: 1280px) 350px"
  style={{
    width: '100%', 
    maxWidth: '450px', 
    minWidth: '150px', 
    maxHeight:'275px',
    minHeight:'275px',
    zIndex: '0',
    objectFit: 'cover',
  }}
/>{/*
      <Image
        src={props.arrayPictures ? firstPicture : ''}
        alt={'imagen propiedad'}
        w={{
          base: '340px',
          customSmMd:'300px',
          sm: '440px',
          md: '300px',
          lg: '310px',
          xl: '350px',
        }}
        minW={'150px'}
        maxW={'450px'}
        height={'275px'}
        objectFit="cover"
      />*/}
      <Box
        bg={'rgba(80, 80, 80, 0.7)'}
        zIndex={30}
        position="absolute"
        color={'white'}
        height={'40px'}
        w={'100%'}
        left={0}
        padding={{ base:'5px 13px', sm: '5px 18px', md: '5px 20px', lg:'5px 20px'}}
        top={'235px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Box display={'flex'}>
          <Box marginRight={'10px'}  display="flex"
    alignItems="center">
            <Text
              fontFamily="Heebo, sans-serif"
              fontWeight={300}
              fontSize={ { base:'11px', sm: '12px', md: '14px'}}>
              <span
                style={{
                  fontWeight: '300',
                  fontSize: '12px',
                  display: 'inline-flex',
                }}>
                <BsFillBuildingsFill
                  size={16}
                  style={{ marginRight: '6px', paddingTop: '3px' }}
                />
              </span>
                {props.property?.totalArea + 'm² '}
            </Text>
          </Box >
          <Text color={'orange'} marginRight={'6px'} marginLeft={'6px'}>|</Text>

          <Box marginRight={'10px'}  display="flex"
    alignItems="center">
            <Text
              fontFamily="Heebo, sans-serif"
              fontWeight={300}
              fontSize={ { base:'11px', sm: '12px', md: '14px'}}>
              <span
                style={{
                  fontWeight: '300',
                  fontSize: '11px',
                  display: 'inline-flex',

                }}>
                <FaBed
                  size={16}
                  style={{ margin: '0 6px', paddingTop: '4px' }}
                />{' '}
              </span>
                {props.property.bedrooms &&
                !props.property.bedrooms.includes('null')
                  ? props.property.bedrooms
                  : 'N/A'}
            </Text>
          </Box>
          <Box  >
          <Text color={'orange'} marginRight={'12px'}  marginLeft={'6px'}>|</Text>
          </Box>

          <Box display="flex"
    alignItems="center">
            <Text
              fontFamily="Heebo, sans-serif"
              fontWeight={300}
              paddingTop= {'3px'} 
              fontSize={ { base:'11px', sm: '12px', md: '14px'}}
              paddingBottom={'2px'}>{formatter.roomsOptions(props.property.rooms) + '  '}
              <span
                style={{
                  fontWeight: '300',
                  fontSize: '10px',
                  display: 'inline-flex',
                  alignItems: 'flex-start',
      
                }}>
               {` AMB.`}
              </span>
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        textAlign={'start'}
        bg={'#656664'}
        padding={'4px 8px 8px 8px'}
        borderTop="3px solid white"
        height={'130px'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-around'}>
        <Box>
          <Text
            fontFamily="Heebo, sans-serif"
            //textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
            fontSize={'14px'}
            p={'2px 0px 3px 5px'}
            color={'white'}>
            {props.property.fakeAddress}
          </Text>
          <Text
            fontFamily="Heebo, sans-serif"
            paddingLeft={'5px'}
            fontSize={{ base:'12px', sm:'11px', }}
            fontWeight={300}
           // textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
            color={'white'}>
            <span>

            {formatter.cleanLocation(props.property?.fullLocation)}

           {/* {props.property.location?.zone
                 ? props.property.location.zone 
                 : ''}
                  {props.property.location?.city ?  '   |   ' + props.property.location?.city 
             : ''
           }
               
               {props.property.location?.country
                   ?  '   |   '+ props.property.location?.country
                   : ''}*/} 
            </span>
          </Text>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderRadius={'5px'}
          padding={'2px 5px'}>
          <Text
            fontFamily="Heebo, sans-serif"
            fontWeight={400}
            fontSize={{ base:'15px', sm:'13px', md:'15px', lg:'15px'}}
            color={'white'}>
            {props.property.realEstateType}
          </Text>

          <Text
            fontFamily="Heebo, sans-serif"
            color={'white'}
            fontWeight={400}
            fontSize={{ base:'17px', sm:'14px', md:'15px', lg:'17px'}}>
         
            {(!props.property?.amountUSD && !props.property?.amountARS ? 'CONSULTAR' :(props.property.amountUSD
              ? 'U$S ' + formatter.formatUSD(props.property.amountUSD)
              : '') + (props.property.amountUSD && props.property.amoutARS ? '||' :'')+
              (props.property.amountARS
                ? ' $ ' + formatter.formatUSD(props.property?.amountARS)
                : ''))}
          </Text>
        
      
        </Box>
        <Text
            fontFamily="Heebo, sans-serif"
            paddingLeft={'5px'}
            fontSize={{ base:'11px', sm:'12px', }}
            fontWeight={300}
           // textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
            color={'white'}>
              <span>{props.property?.operationType.toUpperCase()}</span>
            </Text>
      </Box>
    </Box>
  </Link>

  );
};

export default CardProps;
