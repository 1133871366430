'use client';
import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import UndertakingCard from '../emprendimientos/undertakingCard';

import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Image, Link, Text, textDecoration } from '@chakra-ui/react';
import '../globals.css'
import DevelopmentCard from '../emprendimientos/developmentCard';

const DevelopmentCarousel = (props) => {



  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1025 },
      items: 4,
      slidesToSlide: 2, // Número de tarjetas a mover en cada desplazamiento
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max:649, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const customStyles = {

    
    display:'flex',
    justifyContent:'space-around',
    position: 'relative'
  };

  const customArrowStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    backgroundColor: 'white',
    padding: '4px',
    borderRadius: '30px',
    boxShadow: '0 0 2px rgb(190, 9, 9)',
  };

  const cardWidth = {
    base: '220px',
    sm: '300px',
    md: '250px',
    lg: '310px',
    xl: '350px',
  };

  const containerStyles = {
    width: cardWidth, // Asigna el ancho del contenedor igual al ancho de la tarjeta
    margin: '0 auto', // Centra el contenedor horizontalmente
  };

  return (
  
    <Carousel
    ssr={true}  //centerMode={true}
      deviceType={props.deviceType}
      responsive={responsive}
      containerClass="carousel-container"
      customStyles={customStyles}
      infinite
      //autoPlay
     // autoPlaySpeed={3000}
      removeArrowOnDeviceType={['mobile']}
      // Ajusta el espaciado entre tarjetas
      customLeftArrow={<CustomLeftArrow style={customArrowStyles} />}
      customRightArrow={<CustomRightArrow style={customArrowStyles} />}
    >
       {!props.oportunities && !props.loading ? <Box>No hay emprendiemientos disponibles</Box>    :    props.oportunities.map((project, index) => {
                let arrayPictures;
                try {
                  if (project.pictures?.length) {
                    arrayPictures = JSON.parse(project.pictures);
                  }
                } catch (e) {
                  arrayPictures = project.pictures ? [project.pictures] : [''];
                }
                return (
                  <div key={index} style={containerStyles}>
                    <DevelopmentCard
                      project={project}
                   
                      arrayPictures={arrayPictures}
                    />
                    </div>
                 
                );
              })}
    </Carousel>
  );
};

// Componentes de flechas personalizadas
const CustomLeftArrow = ({ onClick, style ,...rest  }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <div onClick={() => onClick()} style={{ ...style, left: 0,  visibility: currentSlide === 0 ? 'hidden' : 'visible' }}>
       <ArrowBackIcon boxSize={8} color="orange" _hover={{ color: 'grey' }} />
  
    </div>
  );
};

const CustomRightArrow = ({ onClick, style ,...rest }) => {
  const { carouselState: { slidesToShow, currentSlide,totalItems } } = rest;

  const handleClick = () => {
    // Si estamos en el último grupo de slidesToShow, reinicia al primer slide
    if (currentSlide + slidesToShow >= totalItems) {
      onClick(0); // Reinicia al primer slide (índice 0)
    } else {
      onClick(); // Avanza al siguiente slide normalmente
    }
  };

  return (
    <div onClick={handleClick}  style={{ ...style, right: 0,  }}>
     <ArrowForwardIcon boxSize={8} color="orange" _hover={{ color: 'grey' }}  />
 
    </div>
  );
};



export default DevelopmentCarousel;

